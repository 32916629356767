@import "../../../styles/Shared.scss";

.hair-form {
	display: flex;
	flex-direction: column;
	grid-gap: 0.75rem;
	color: $brand_color_black;
	max-width: 35rem;
	width: calc(100% - 4rem);
	padding: 1rem;
	border-radius: 4px;
	box-shadow: $button_shadow;
	z-index: 1;
	overflow: hidden;
	position: relative;
	margin: auto;
	animation: fade-in 0.3s linear;
	// @include backgroundGradientEffect(){
	//     background: linear-gradient(60deg, #FFFFFF,#F1F1F1);
	// }

	&__title {
		width: 100%;
		text-align: center;
		color: $brand_color_black;
		margin: 0.5rem auto;
		font-size: 2rem;
		font-weight: bold;
	}

	&__field {
		display: flex;
		flex-direction: column;
		grid-row-gap: 0.1rem;
		animation: fade-in 0.3s linear;

		&--error {
			color: $brand_color_pink;
			font-weight: bold;
		}
	}

	&__label {
		text-align: left;
		font-size: 1rem;
		transition: $quick_transition;
		margin: 0;
	}

	&__input {
		border: 1px solid $brand_color_blue;
		border-radius: 3px;
		padding: 0.5rem;
		font-size: 1rem;
		transition: $quick_transition;
		box-shadow: $toggle_shadow;

		&--error {
			border-color: $brand_color_pink;
			background: $brand_color_pink__alt_4;
		}
	}

	&__submit {
		position: relative;
		overflow: hidden;
		border: none;
		z-index: 1;
		color: white;
		border-radius: 3px;
		padding: 0.45rem;
		font-size: 1.25rem;
		cursor: pointer;
		transition: $quick_transition;
		box-shadow: 0px 0px 5px 0px #126086;
		margin-top: 0.25rem;
		font-weight: bold;
		// @include backgroundGradientEffect() {
		//     background: $brand_gradient_blue;
		// }
		// Directly set the background color to #126086
		background-color: #126086;

		&:hover {
			background-color: white; /* Darker shade on hover for better UI */
			color: #126086;
			border-radius: 5px;
			border: 3px solid #126086;
		}

		@include state() {
			filter: contrast(120%);
		}
	}

	&__dropdown {
		width: 100%;
		padding: 0.5rem 0.25rem;
		border-radius: 4px;
		border: 1px solid $brand_color_blue;
		box-shadow: $toggle_shadow;
		font-size: 1rem;
		appearance: none;
	}
	// button{
	//     overflow: hidden;
	//     border: none;
	//     z-index: 1;
	//     color: white;
	//     border-radius: 3px;
	//     padding: 0.45rem;
	//     font-size: 1.25rem;
	//     cursor: pointer;
	//     transition: $quick_transition;
	//     box-shadow: $box_shadow_blue;
	//     margin-top: 0.25rem;
	//     @include state(){
	//         filter: contrast(120%);
	//     }
	//     // @include backgroundGradientEffect(){
	//     //     background: $brand_gradient_blue;
	//     // }
	// }


	&__recaptcha-error {
		animation: fade-in 0.2s linear;
		color: $brand_color_pink;
		font-size: 1rem;
		font-weight: bold;
		width: 100%;
		text-align: left;
		margin-bottom: 0;
	}
}

.react-tel-input {
	font-size: 15px;
	position: relative;
	width: 100%;
	font-family: 'Nunito', 'Open Sans', sans-serif;
	margin-top: 1rem !important;

	.special-label {
		position: absolute;
		z-index: 1;
		left: 0px !important;
		top: -20px !important;
		background: none !important;
		padding: 0px !important;
		white-space: nowrap;
		display: block !important;
		font-size: 1rem !important;
	}

	.form-control {
		position: relative;
		font-size: 14px;
		letter-spacing: .01rem;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-left: 48px;
		margin-left: 0;
		background: #FFFFFF;
		border: 1px solid $brand_color_blue !important;
		box-shadow: $toggle_shadow !important;
		border-radius: 3px !important;
		line-height: 25px;
		height: 35px;
		width: 100% !important;
		outline: none;
	}

	.flag-dropdown {
		position: absolute;
		top: 0;
		bottom: 0;
		padding: 0;
		background-color: #f5f5f5;
		border: none !important;
		border-radius: 3px 0 0 3px;
	}

	.selected-flag {
		border: 1px solid $brand_color_blue !important;
	}

	.country-list {
		border-radius: 4px !important;
		box-shadow: $box_shadow_blue !important;
	}
}

.hair-form-popup {
	animation: fade-in 0.2s linear;
	max-width: 31rem;
	width: calc(100% - 3rem);
	display: flex;
	justify-content: center;
	align-items: center;
	background: $brand_color_green__alt_6;
	color: $brand_color_green__alt_5;
	border-radius: 4px;
	padding: 0.5rem;
	margin-bottom: 2rem;
	font-size: 1.25rem;
	font-weight: 400;
	position: relative;
	margin-left: auto;
	margin-right: auto;

	&:after {
		position: absolute;
		content: " ";
		width: 30px;
		height: 16px;
		bottom: -16px;
		position: absolute;
		left: calc(50% - 15px);
		clip-path: polygon(100% 0%, 0 0, 50% 100%);
		background: inherit;
	}

	&--error {
		background: $brand_color_pink__alt_1;
		color: $brand_color_pink;

		&:after {
			position: absolute;
			content: " ";
			width: 30px;
			height: 16px;
			bottom: -16px;
			position: absolute;
			left: calc(50% - 15px);
			clip-path: polygon(100% 0%, 0 0, 50% 100%);
			background: inherit;
		}
	}
}

.hair-form-wrapper {
	width: 50%;
	max-width: 35rem;
	animation: fade-in 0.5s linear;
	margin-bottom: 1rem;

	@include breakpoint(desktop) {
		width: 100%;
		margin: 1rem auto;
	}
}

.hair-form-link {
	color: #126086;
	font-size: 1.75rem;
	display: flex;
	flex-direction: row;
	grid-column-gap: 0.5rem;
	align-items: center;
	font-weight: bold;
	text-decoration: none;
	width: max-content;
	padding-left: 1rem;
	margin-bottom: 1rem;
	transition: $standard_transition;

	@include state() {
		color: $brand_color_blue__alt_1;
	}
}

.react-datepicker-wrapper {

	input {
		border: 1px solid $brand_color_blue;
		border-radius: 3px;
		padding: 0.5rem;
		font-size: 1rem;
		transition: $quick_transition;
		box-shadow: $toggle_shadow;
		width: calc(100% - 1.25rem);
	}
}

.react-datepicker {

	&__year-read-view {
		font-size: 1rem;

		&--down-arrow {
			border-color: $brand_color_black !important;
			height: 8px !important;
			width: 8px !important;
		}
	}

	&__year-dropdown {
		box-shadow: $button_shadow;
	}

	&__year-option {
		font-size: 1rem;
		transition: $quick_transition;
		position: relative;
		z-index: 1;
		overflow: hidden;

		@include state() {
			// @include backgroundGradientEffect(){
			//     background: $brand_gradient_blue;
			// }
		}

		&:first-child::before {
			content: "\2191";
			position: absolute;
			display: block;
			z-index: 1;
			top: 2px;
			left: calc(50% - 15px);
			width: 30px;
			height: 30px;
			margin: auto;
			font-size: 1.25rem;
		}

		&:last-child::before {
			content: "\2193";
			display: block;
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: calc(50% - 15px);
			width: 30px;
			height: 30px;
			margin: auto;
			font-size: 1.25rem;
		}
	}
}

.order-prefix-select {
	float: left;
	border: 1px solid #00c2cb;
	border-radius: 3px;
	box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
	font-size: 1rem;
	padding: 0.5rem;
	transition: all .15s linear;
	order: 1px solid #00c2cb;
	border-radius: 3px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
	font-size: 1rem;
	padding: .5rem;
	transition: all .15s linear;
	appearance: none;
}

.activation-code-input {
	text-transform: uppercase;

	&::placeholder {
		text-transform: none;
	}
}
