@import "../../../styles/Shared.scss";

.thank-you {
	animation: fade-in 0.3s linear;
	width: calc(100%);
	text-align: left;

	&__title {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	&__submission-code {
		color: black;
		margin-bottom: 1rem;
	}

	&__button {
		display: flex;
		align-items: center;
		grid-gap: 0.5rem;
		position: relative;
		overflow: hidden;
		border: none;
		z-index: 1;
		color: white;
		border-radius: 3px;
		padding: 0.2rem 0.6rem;
		font-size: 1rem;
		cursor: pointer;
		transition: $quick_transition;
		box-shadow: $box_shadow_green;
		margin-top: 0.25rem;
		font-weight: bold;
		text-decoration: none;
		width: max-content;

		@include state() {
			filter: contrast(120%);
		}
		// @include backgroundGradientEffect(){
		//     background: $brand_gradient_green;
		// }

		@include breakpoint(mobile) {
			width: 100%;
		}

		svg {
			width: 1.75rem;
			height: 1.75rem;
		}
	}

	&__subtitle {
		margin-top: 1rem;

		h3 {
			font-size: 1rem;
		}
	}

	&__steps {
		padding: 0;
		list-style-type: none;
		font-size: 1rem;
	}

	&__step {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 2rem;

		img {
			animation: fade-in 0.3s linear;

			@include breakpoint(tablet) {
				display: none;
			}
		}

		h3 {
			font-size: 1.05rem;
		}

		p {
			font-size: 0.9rem;
		}
	}
}
