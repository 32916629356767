@import "../../styles/Shared.scss";

.hair-retest{

    width: 100%;
    max-width: 35rem;

    &__modal-wrapper{
        display: flex;
        flex-direction: column;
        grid-row-gap: 1rem;
    }

    &__modal-text{
        font-size: 1.1rem;
    }

    &__modal-button{
        width: max-content;
        margin: auto;
    }

    @include breakpoint(tablet){
        max-width: 100%;
    }

    &__back-button{
        color: $brand_color_blue;
        font-size: 1.75rem;
        display: flex;
        flex-direction: row;
        grid-column-gap: 0.5rem;
        align-items: center;
        font-weight: bold;
        text-decoration: none;
        width: max-content;
        padding-left: 1rem;
        margin-bottom: 1rem;
        transition: $standard_transition;
    
        @include state(){
            color: $brand_color_blue__alt_1;
        }
    }

    &__form{
        display: flex;
        flex-direction: column;
        grid-gap: 0.75rem;
        color: $brand_color_black;
        max-width: 35rem;
        width: calc(100% - 4rem);
        padding: 1rem;
        border-radius: 4px;
        box-shadow: $button_shadow;
        z-index: 1;
        overflow: hidden;
        position: relative;
        margin: auto auto 2rem auto;
        animation: fade-in 0.3s linear;
    
        // @include backgroundGradientEffect(){
        //     background: linear-gradient(60deg, #FFFFFF,#F1F1F1);
        // }
    }

    &__title{
        width: 100%;
        text-align: center;
        color: $brand_color_black;
        margin: 0.5rem auto;
        font-size: 2rem;
        font-weight: 400;
        font-family: "Open Sans", sans-serif, Helvetica;
    }

    &__field{
        display: flex;
        flex-direction: column;
        grid-row-gap: 0.1rem;
        animation: fade-in 0.3s linear;

        &--error{
            color: $brand_color_pink;
            font-weight: bold;
            
            label{
                font-size: 1.1rem;
            }
        }
    }

    &__label{
        text-align: left;
        font-size: 1rem;
        transition: $quick_transition;
        margin: 0;
    }

    &__input{
        border: 1px solid $brand_color_blue;
        border-radius: 3px;
        padding: 0.5rem;
        font-size: 1rem;
        transition: $quick_transition;
        box-shadow: $toggle_shadow;

        &--error{
            border-color: $brand_color_pink;
            background: $brand_color_pink__alt_4;
        }
    }

    button{
        overflow: hidden;
        border: none;
        z-index: 1;
        color: white;
        border-radius: 3px;
        padding: 0.45rem;
        font-size: 1.25rem;
        cursor: pointer;
        transition: $quick_transition;
        box-shadow: $box_shadow_blue;
        margin-top: 0.25rem;
        position: relative;

        @include state(){
            filter: contrast(120%);
        }

        // @include backgroundGradientEffect(){
        //     background: $brand_gradient_blue;
        // }
    }
}