@import "../../styles/Shared.scss";

.home-page{
    animation: fade-in 0.4s linear;
    display: flex;
    flex-direction: column;
    grid-row-gap: 0.5rem;
    max-width: 40rem;
    padding: 1rem;
    margin: 0.5rem auto;
    width: calc(100% - 4rem);

    &__embedded-video{
        margin: auto;
        box-shadow: $box_shadow;
        max-width: 100%;
        width: 100%;
        margin-top: 2rem;

        iframe{
            width: 100%;
            height: 350px;

            @include breakpoint(mobile){
                height: 250px;
            }

            @include breakpoint(tablet){
                height: 300px;
            }
        }

    }

    &__header{
        margin: auto;
    }

    &__instructions{
        margin: auto;
    }

    &__instructions-grid{
        display: flex;
        flex-direction: row;
        list-style-type: none;
        grid-gap: 10rem; 
        padding: 0;
        margin-bottom: 0rem;

        @include breakpoint(mobile){
            flex-direction: column;
        }
    }

    &__instructions-link{
        width: 100%;

        a{
            color: #126086;
            text-decoration: none;
            font-size: 1.65rem;
            font-weight: bold;
            transition: $quick_transition;
            border-bottom: none;

            @include state(){
                border-bottom: 3px solid $brand_color_blue;
            }
        }
        
        @include breakpoint(mobile){
            width: calc(100% - 2rem);
        }
    }

    &__tests-grid{
        display: flex;
        flex-direction: row;
        list-style-type: none;
        grid-gap: 3rem;
        padding: 0;

        @include breakpoint(mobile){
            flex-direction: column;
        }
    }

    &__test{
        width: 100%;

        @include breakpoint(mobile){
            // width: calc(100% - 2rem);
            // height: 100px;
        }

        @include state(){
            box-shadow: $box_shadow_blue;
        }

        a{
            display: flex;
            justify-content: center;
            align-items: center;
            grid-row-gap: 0.25rem;
            text-decoration: none;
            font-size: 3rem;
            color: black;
            transition: $quick_transition;

            svg{
                padding: 0.5rem;
            }

            @include state(){
                color: $brand_color_blue;
            }
        }

        p{
            margin: 0;
            font-size: 1.75rem;
        }

        img{
            width: 4rem;
            height: 4rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .hide-on-mobile {
      display: none;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .hide-on-mobile {
      display: block;
    }
  }
  
  .blood-hair-test-link {
    display: flex;            /* Align items horizontally */
    align-items: center;      /* Center items vertically */
    background-color: #126086; /* Background color for the button */
    color: white;             /* Text color */
    padding: 10px 20px;       /* Padding for better spacing */
    text-decoration: none;    /* Remove underline from link */
    border-radius: 5px;       /* Rounded corners */
  }
  
  .blood-hair-test-link:hover {
    background-color: #0e4a60; /* Darker shade on hover for better UI */
  }

  .blood-icon {
    fill: #ED0C6E;             /* Fill color for the droplet icon */
    margin-right: 10px;        /* Space between icon and text */
  }
  
  .blood-hair-test-link p {
    margin: 0;                /* Remove default margin from the <p> tag */
  }

  .instructions-blood-hair-test-link {
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 5px;
    border: 3px solid #126086; 
    color: #126086;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: white; /* Darker shade on hover for better UI */
      color: #126086;
    }

    p {
      margin: 0; /* Remove default margin from the <p> tag */
      font-size: 1rem; /* Adjust font size if needed */
    }
}