// Brand colors
$brand_color_white: #FFFFFF;

$brand_color_black: #1B1B1B;

$brand_color_gray: #82898E;
$brand_color_gray__alt_1: #EFF1F3;
$brand_color_gray__alt_2: #606063;
$brand_color_gray__alt_3: #F6F8FA;
$brand_color_gray__alt_4: #E5E5E5;
$brand_color_gray__alt_5: #C4C4C4;
$brand_color_gray__alt_6: #F3F3F3;

$brand_color_yellow: #F1C40F;
$brand_color_yellow__alt_1: #E9D278;
$brand_color_yellow__alt_2: #F8E187;

$brand_color_orange: #F1970F;

$brand_color_pink: #ED0C6E;
$brand_color_pink__alt_1: #FDDFEC;
$brand_color_pink__alt_2: #F13D8B;
$brand_color_pink__alt_3: #BE0A58;
$brand_color_pink__alt_4: #FFD1DC;

$brand_color_purple: #D10FF1;
$brand_color_purple__alt_1: #BC0DD8;
$brand_color_purple__alt_2: #D527F2;

$brand_color_green: #2ECC71;
$brand_color_green__alt_1: #40D47E;
$brand_color_green__alt_2: #96E5B8;
$brand_color_green__alt_3: #29B765;
$brand_color_green__alt_4: #2ECC70;
$brand_color_green__alt_5: #1b7943;
$brand_color_green__alt_6: #b7f0d0;

$brand_color_blue: #00c2cb;
$brand_color_blue__alt_1: #00aab2;
$brand_color_blue__alt_2: #00dae5;
$brand_color_blue__alt_3: #00f3fe;


// Backdrop
$backdrop: rgb(40, 44, 52);
$backdrop_light: #F2F2F2;

// Box shadows
$box_shadow: ( 0px 0px 20px 0px rgba(0,0,0,0.4) );
$button_shadow: ( 0px 0px 15px 0px rgba(0,0,0,0.2) );
$toggle_shadow: ( 0px 0px 5px 0px rgba(0,0,0,0.2));

$brand_box_shadow: ( 0px 0px 15px 0px $brand_color_green );
$brand_box_shadow_small: ( 0px 0px 8px 0px $brand_color_green );

// Branded box shadows
$box_shadow_pink: ( 0px 0px 5px 0px $brand_color_pink);
$box_shadow_pink__alt_1: ( 0px 0px 5px 0px $brand_color_pink__alt_1);
$box_shadow_green: ( 0px 0px 5px 0px $brand_color_green);
$box_shadow_purple: ( 0px 0px 5px 0px $brand_color_purple);
$box_shadow_yellow: ( 0px 0px 5px 0px $brand_color_yellow);
$box_shadow_blue: ( 0px 0px 15px 0px $brand_color_blue );

// Gradients
$gradient_bg_size: 400% 400%;
$brand_gradient_green: linear-gradient( 60deg, $brand_color_green, $brand_color_green__alt_3, $brand_color_green__alt_4, $brand_color_green__alt_1);
$brand_gradient_blue: linear-gradient( 60deg, $brand_color_blue, $brand_color_blue__alt_2, $brand_color_blue__alt_3 );
$brand_gradient_purple: linear-gradient( 60deg, $brand_color_purple, $brand_color_purple__alt_1, $brand_color_purple__alt_2 );
$brand_gradient_yellow: linear-gradient( 60deg, $brand_color_yellow, $brand_color_yellow__alt_1, $brand_color_yellow__alt_2 );
$brand_gradient_gray: linear-gradient( 60deg, $brand_color_gray__alt_1, $brand_color_gray__alt_3, $brand_color_gray__alt_4 ); 
$brand_gradient_gray_light: linear-gradient( 60deg, $brand_color_gray__alt_1, $brand_color_gray__alt_3 );
$brand_gradient_pink: linear-gradient( 60deg, $brand_color_pink, $brand_color_pink__alt_2, $brand_color_pink__alt_3 );

// Gaps
$gap: 1rem;
$gap-double: 2rem;