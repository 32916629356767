// Slowly increase opacity of the component
@keyframes fade-in{
    0%{ 
      opacity: 0; 
    }
    100%{ 
      opacity: 1; 
    }
}

// Increase opacity of the card and slide into the screen
@keyframes slide-in{
    0%{
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100%);
    }
    100%{
      opacity: 1;
      filter: blur(0);
      transform: translateX(0);
    }
}

@keyframes slide-up{
  0%{
    opacity: 0;
    transform: translateY(100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
}

// Change the gradient of the colors assuming the background size is big enough
@keyframes gradient{
    0%{ background-position: 0% 0%; }
    50%{ background-position: 100% 100%; }
    100%{ background-position: 0% 0%; }
}


// Gradient effect that's more fiddly but less resource intensive
@keyframes efficient-gradient{

  0%{ 
      transform: translateX(0%); 
      -webkit-transform: translateX(0%);
  }

  25%{ 
      transform: translate(-37.5%); 
      -webkit-transform: translateX(-37.5%);
  }

  50%{ 
      transform: translateX(-75%);
      -webkit-transform: translateX(-75%); 
  }

  75%{ 
      transform: translateX(-37.5%); 
      -webkit-transform: translateX(-37.5%);
  }

  100%{ 
      transform: translateX(0%); 
      -webkit-transform: translateX(0%);
  }
  
}

// Pulse hover effect
@keyframes pulse{
    0%{ transform: scale(1); }
    50%{ transform: scale(1.03); }
    100%{ transform: scale(1); }
}

@keyframes pulse-shrink{
  0%{ transform: scale(1); }
  50%{ transform: scale(0.90); }
  100%{ transform: scale(1); }
}

// Spin effect
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

// Bouncing effect
@keyframes bounce {
  0%{ bottom: 0; }
  50%{ bottom: 0.25rem; }
  100%{ bottom: 0; }
}

// Sway effect
@keyframes sway {
  0%{ 
    transform: rotate(0deg);  
    top: 0; 
  }
  25%{ 
    transform: rotate(6deg); 
    top: -2px;
  }
  50%{ 
    transform: rotate(0deg); 
    top: 4px; }
  75%{ 
    transform: rotate(-6deg); 
    top: -2px;
  }
  100%{ 
    transform: rotate(0deg);  
    top: 0; 
  }
}

@keyframes shake {
  0%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(5deg);
  }
  50%{
    transform: rotate(0deg);
  }
  75%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

// Spinning hourglass animation
@keyframes hourglass {
  0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
      transform: rotate(1800deg);
  }
}

// Standard transition
$standard_transition: all 0.25s linear;
$quick_transition: all 0.15s linear;