@import "../src/styles/Shared.scss";

.app {
  text-align: center;
  min-height: 100vh;
  background: $backdrop_light;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fade-in 0.4s linear;
  padding: 1rem;

  p {
    font-family: "Open Sans", sans-serif, Helvetica;
  }

  &__logo {
    width: 100%;
    margin: 0 auto;
    max-width: 350px;

    img {
      width: 100%;
      height: auto;
      margin-bottom: 1rem;
    }
  }
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #f1f1f1;
  text-align: center;
  padding: 1em 0;
  width: 100%;
}
