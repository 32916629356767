

/* Responsive */
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;

@mixin breakpoint($point){

    @if $point == mobile{
        @media ( max-width: $mobile ){ @content; }
    }

    @else if $point == tablet{
        @media ( max-width: $tablet ){ @content; }
    }

    @else if $point == desktop{
        @media ( max-width: $desktop ){ @content; }
    }

}

@mixin breakpoint-min($point){

    @if $point == mobile{
        @media ( min-width: $mobile ){ @content; }
    }

    @else if $point == tablet{
        @media ( min-width: $tablet ){ @content; }
    }

    @else if $point == desktop{
        @media ( min-width: $desktop ){ @content; }
    }

}