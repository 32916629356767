@import "../../styles/Shared.scss";

.privacy-page__header, .privacy-page__copy {
    margin: 0;
    text-align: left;
    font-family: Open Sans, sans-serif, Helvetica;
}

ul.privacy-page__copy li {
    text-align: left !important;
    font-family: Open Sans, sans-serif, Helvetica;
}

table.privacy-page__copy {
    font-family: Open Sans, sans-serif, Helvetica;
    border-spacing: 0;
}

.privacy-page__copy th:first-of-type, .privacy-page__copy td:first-of-type {
    border-left: 1px solid;
}
.privacy-page__copy th, .privacy-page__copy td {
    border-right: 1px solid;
    border-bottom: 1px solid;
}
.privacy-page__copy th {
    border-top: 1px solid;
}
