@import "../../styles/Shared.scss";

.hair-instructions-page{
    
}


.home-page{
    animation: fade-in 0.4s linear;
    display: flex;
    flex-direction: column;
    grid-row-gap: 0.5rem;
    max-width: 40rem;
    padding: 1rem;
    margin: 0.5rem auto;
    width: calc(100% - 4rem);

    &__embedded-video{
        margin: auto;
        box-shadow: $box_shadow;
        max-width: 100%;
        width: 100%;
        margin-top: 2rem;

        iframe{
            width: 100%;
            height: 350px;

            @include breakpoint(mobile){
                height: 250px;
            }

            @include breakpoint(tablet){
                height: 300px;
            }
        }

    }

    &__header{
        margin: auto;
    }
}

.instructions-hair-test-link {
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 5px;
    background-color: #126086;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 50%;
    margin: auto;

    &:hover {
      background-color: white; /* Darker shade on hover for better UI */
      color: #126086;
      border-radius: 5px;
      border: 3px solid #126086; 
    }

    p {
      margin: 0; /* Remove default margin from the <p> tag */
      font-size: 1rem; /* Adjust font size if needed */
    }
}

@media (max-width: 768px) {
    .instructions-hair-test-link {
        width: 80%;
        padding: 10px 15px;
    }

    .instructions-hair-test-link p {
        font-size: 0.9rem; /* Adjust font size if needed */
    }
}

.hair-page{
    animation: fade-in 0.4s linear;
    display: flex;
    flex-direction: column;
    grid-row-gap: 0.5rem;
    max-width: 40rem;
    padding: 1rem;
    margin: 0.5rem auto;
    width: calc(100% - 4rem);

            @include breakpoint(mobile){
                height: 250px;
            }

            @include breakpoint(tablet){
                height: 300px;
            }
        
&__hair-instruction{

    @include state(){
        // box-shadow: $box_shadow_blue;
    }

    a{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row-gap: 0.25rem;
        text-decoration: none;
        font-size: 3rem;
        color: white;
        transition: $quick_transition;

        svg{
            padding: 0.5rem;
        }
    }

    p{
        margin: 0;
        font-size: 1.25rem;
    }

    img{
        width: 4rem;
        height: 4rem;
    }
}
}