@import "../../styles/Shared.scss";

.checkbox {
    position: relative;
    padding: 10px;
    background-color: aliceblue; /* Light blue background */
    border: 2px solid #126086; /* Border color */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 10px; /* Space between checkboxes */
    color: #126086;

    &__label {
        font-size: 0.95rem;
        display: flex;
        justify-content: space-between; /* Align items at the ends */
        align-items: center;
        text-align: left;
        cursor: pointer;
        padding-right: 26px; /* Space for checkbox */
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover .tickbox {
        background-color: #ccc;
    }

    input:checked ~ .tickbox {
        background-color: #126086;
    }

    input:checked ~ .tickbox:after {
        display: block;
    }

    .tickbox:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .tickbox {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        height: 20px;
        width: 20px;
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 0 5px 0 #126086;
        transition: 0.3s;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    // .tickbox-text {
    //     position: absolute;
    //     top: 20%; /* Adjust as needed */
    //     right: 10px;
    //     font-size: 0.75rem;
    //     color: #126086;
    // }
}
