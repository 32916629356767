@import "../../styles/Shared.scss";

.page-404{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1{
        font-size: 2.25rem;
    }

    a{
        color: $brand_color_blue;
        font-size: 1.75rem;
        font-weight: bold;
    }
}